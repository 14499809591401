import React from "react"
import MainLayout from '../../layouts/MainLayout'
import OurWorkComponent from '../../components/OurWorkComponent'

export default function OurWork() {
  return (
    <MainLayout>
        <section className="our-work-banner" />
        <OurWorkComponent />
    </MainLayout>
  )
}
